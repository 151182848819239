/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Produkty značky Bunny Soft"}>
        <Column className="css-7t2vty --style2 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e0f1830b92614430908ea257e0e819ed_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e0f1830b92614430908ea257e0e819ed_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e0f1830b92614430908ea257e0e819ed_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e0f1830b92614430908ea257e0e819ed_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e0f1830b92614430908ea257e0e819ed_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e0f1830b92614430908ea257e0e819ed_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e0f1830b92614430908ea257e0e819ed_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/35535/e0f1830b92614430908ea257e0e819ed_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Title className="title-box fs--36 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"\"Zažijte mimořádnou hebkost s každým dotykem - toaletní papír Bunny Soft, pro pocit čistoty a pohody v každém okamžiku.\""}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wzs7w0cmsrq"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={""}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/35535/d9e5f1a9c66f45eea93efcf9646317fa.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={""} position={null}>
              </Image>

              <Text className="text-box fs--22" style={{"maxWidth":950}} content={"<span style=\"font-weight: bold;\">&nbsp; &nbsp; Exkluzivní distributor pro ČR a SK.<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={""}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l12w pb--60 pt--60" name={"2ms3bgdjw0k"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Makulatura 18g/m2"} src={"https://cdn.swbpg.com/t/35535/9716191ccc574a3b8e6836a5ae33ed10_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/9716191ccc574a3b8e6836a5ae33ed10_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/9716191ccc574a3b8e6836a5ae33ed10_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/9716191ccc574a3b8e6836a5ae33ed10_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/9716191ccc574a3b8e6836a5ae33ed10_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/9716191ccc574a3b8e6836a5ae33ed10_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" content={"2 vrstvý toaletní papír 8 rolí <br>Makulatura 2 x 18g/m2.&nbsp;<br>Paleta 64 balení x 8.<br>Počet lístků = 180.<br>Kod objednání 2223<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/897988ff26324e399b75d7e2411a0041_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/897988ff26324e399b75d7e2411a0041_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/897988ff26324e399b75d7e2411a0041_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/897988ff26324e399b75d7e2411a0041_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/897988ff26324e399b75d7e2411a0041_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"2 vrstvý toaletní papír 8 rolí\n¨Makulatura 2 x 18g/m2. <br>&nbsp;Paleta 64 balení x 8.\n<br>Počet lístků = 180.\n<br>Kod objednání 2964"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/ed66f66d74c04445baa9c3008503e2a4_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/ed66f66d74c04445baa9c3008503e2a4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/ed66f66d74c04445baa9c3008503e2a4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/ed66f66d74c04445baa9c3008503e2a4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/ed66f66d74c04445baa9c3008503e2a4_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/ed66f66d74c04445baa9c3008503e2a4_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" content={"<br>2 vrstvý toaletní papír 8 rolí 15m. Makulatura 2 x 14,5g/m2. <br>&nbsp;Paleta 64 balení x 8.\n<br>Počet lístků = 180.\n<br>Kod objednání 5156<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/7cde06ab196c4912aeab9c598b9c5545_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":764}} srcSet={"https://cdn.swbpg.com/t/35535/7cde06ab196c4912aeab9c598b9c5545_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/7cde06ab196c4912aeab9c598b9c5545_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/7cde06ab196c4912aeab9c598b9c5545_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/7cde06ab196c4912aeab9c598b9c5545_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/7cde06ab196c4912aeab9c598b9c5545_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" content={"3 vrstvý toaletní papír 8 rolí 15m. 100% cel. 3 x 14,5g/m2.\n<br>&nbsp;Paleta 56 balení x 8.&nbsp;<br>&nbsp;Kod objednání 7303"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/20529d99003e48ca9572fab90619c5ca_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/20529d99003e48ca9572fab90619c5ca_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/20529d99003e48ca9572fab90619c5ca_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/20529d99003e48ca9572fab90619c5ca_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/20529d99003e48ca9572fab90619c5ca_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"3 vrstvý toaletní papír 10 rolí.<br>&nbsp;100% cel. 3 x 14,5g/m2.\n<br>&nbsp;Paleta 70 balení x 10.\n<br>Počet lístků = 200.\n<br>Kod objednání 7327"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/68e6b623fba9446eb586a6ec75dc4779_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/68e6b623fba9446eb586a6ec75dc4779_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/68e6b623fba9446eb586a6ec75dc4779_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/68e6b623fba9446eb586a6ec75dc4779_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/68e6b623fba9446eb586a6ec75dc4779_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"3 vrstvý toaletní papír 10 rolí. 100% cel. 3 x 14,5g/m2.\n<br>&nbsp;Paleta 70 balení x 10.\n<br>Počet lístků = 200.\n<br>Kod objednání 7341"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/9c9c837b46c94177b41172f8c9602722_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/9c9c837b46c94177b41172f8c9602722_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/9c9c837b46c94177b41172f8c9602722_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/9c9c837b46c94177b41172f8c9602722_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/9c9c837b46c94177b41172f8c9602722_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/9c9c837b46c94177b41172f8c9602722_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" content={"<br><br>3 vrstvý toaletní papír 8&nbsp;rolí.\n<br>&nbsp;100% cel. 3 x 14,5g/m2.\n<br>&nbsp;Paleta 56 balení x 8.\n<br>Kod objednání 7358"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/79dd7a2b5df14365a43df9ab7b899e6f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/35535/79dd7a2b5df14365a43df9ab7b899e6f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/79dd7a2b5df14365a43df9ab7b899e6f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/79dd7a2b5df14365a43df9ab7b899e6f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/79dd7a2b5df14365a43df9ab7b899e6f_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"<br><br><br>2 vrstvý toaletní papír 8 rolí.\n<br>&nbsp;100% cel. 2 x 14,5g/m2.<br>&nbsp;Paleta 64 balení x 8.\n<br>Počet lístků = 180.\n<br>Kod objednání 0274"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"tqrvdkimh"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/6a63e0958b9e41d48b3967a09e4abb29_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/6a63e0958b9e41d48b3967a09e4abb29_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/6a63e0958b9e41d48b3967a09e4abb29_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/6a63e0958b9e41d48b3967a09e4abb29_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/6a63e0958b9e41d48b3967a09e4abb29_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"3 vrstvý toaletní papír 16 rolí 15m. 100% cel. 3 x 14,5g/m2.\n<br>&nbsp;Paleta 132 balení. <br>Kod objednání 7334"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/18b50ba802224467b8eecbac216ad269_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/18b50ba802224467b8eecbac216ad269_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/18b50ba802224467b8eecbac216ad269_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/18b50ba802224467b8eecbac216ad269_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/18b50ba802224467b8eecbac216ad269_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"2 vrstvý toaletní papír 4 role.<br>Počet lístků 500.<br>&nbsp;100% cel. 2x 14,5g/m2.\n<br>&nbsp;Paleta 33 balení x 36.\n<br>Kod objednání 4005"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/bd1e198ff62349d6b76fae5074f30054_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/bd1e198ff62349d6b76fae5074f30054_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/bd1e198ff62349d6b76fae5074f30054_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/bd1e198ff62349d6b76fae5074f30054_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/bd1e198ff62349d6b76fae5074f30054_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"3 vrstvý toaletní papír 4 role.\nPočet lístků 300.\n<br>&nbsp;100% cel. 3 x 18g/m2.\n<br>&nbsp;Paleta 33 balení x 36.\n<br>Kod objednání 4012"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/409aa6237f4a42e584ebabba7c6a21d3_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/409aa6237f4a42e584ebabba7c6a21d3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/409aa6237f4a42e584ebabba7c6a21d3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/409aa6237f4a42e584ebabba7c6a21d3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/409aa6237f4a42e584ebabba7c6a21d3_s=1400x_.jpg 1400w"}>
              </Image>

              <Text className="text-box" content={"1 vrstvý toaletní papír 8 rolí.<br>Délka 36,5m.<br>&nbsp;Makulatura cel. 33g/m2.\n<br>&nbsp;Paleta 64 balení x 33.\n<br>Kod objednání 0014"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/130e93f8393c4a0fa7cc166bc936aa82_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/130e93f8393c4a0fa7cc166bc936aa82_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/130e93f8393c4a0fa7cc166bc936aa82_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/130e93f8393c4a0fa7cc166bc936aa82_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/130e93f8393c4a0fa7cc166bc936aa82_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/130e93f8393c4a0fa7cc166bc936aa82_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" content={"2 vrstvé kapesníky <br>100ks v krabičce.\n<br>100% cel. 2x 17g/m2.\n<br>&nbsp;Paleta 28 balení x 40.\n<br>Kod objednání 0458"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/19f17f0272a5468ab1b33ae241e5ff76_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/19f17f0272a5468ab1b33ae241e5ff76_s=350x_.jpg 350w, https://cdn.swbpg.com/t/35535/19f17f0272a5468ab1b33ae241e5ff76_s=660x_.jpg 660w, https://cdn.swbpg.com/t/35535/19f17f0272a5468ab1b33ae241e5ff76_s=860x_.jpg 860w, https://cdn.swbpg.com/t/35535/19f17f0272a5468ab1b33ae241e5ff76_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/35535/19f17f0272a5468ab1b33ae241e5ff76_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box" content={"3 vrstvé kapesníky 10x10 ks&nbsp;<br>&nbsp;100% cel. 3 x 15g/m2.\n<br>&nbsp;Paleta 42 balení x 24.\n<br>Kod objednání 0243"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/35535/090617f404af47f2905ad8154b213fe9_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/35535/090617f404af47f2905ad8154b213fe9_s=350x_.png 350w, https://cdn.swbpg.com/t/35535/090617f404af47f2905ad8154b213fe9_s=660x_.png 660w, https://cdn.swbpg.com/t/35535/090617f404af47f2905ad8154b213fe9_s=860x_.png 860w, https://cdn.swbpg.com/t/35535/090617f404af47f2905ad8154b213fe9_s=1400x_.png 1400w, https://cdn.swbpg.com/t/35535/090617f404af47f2905ad8154b213fe9_s=2000x_.png 2000w"}>
              </Image>

              <Text className="text-box" content={"1 vrstva Wc vlhčené ubrousky<br>60 lístků.<br>100% celuzoza 57g/m2<br>Paleta 70 balení x 35.<br>Kod objednání 6504<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"3ukxwap772a"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}